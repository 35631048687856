import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default ({}) => {
  return (
    <Layout>
      <HeadMeta pageTitle="Search Tips" />
      <PageHeader>Search Tips</PageHeader>
      <Breadcrumb
        route={[
          { name: "Scanned card catalogue", href: "/" },
          { name: "Search Tips", href: "/tips/" },
        ]}
      />
      <h2>Personal author</h2>
      <ul>
        <li>
          This is the best way to search for a particular book as only some
          titles are listed.
        </li>
        <li>
          Type in the surname first, followed by a comma, then a space, then the
          initial or first name. For example:
          <br />
          <ul>
            <li>
              <code>Smith, R</code>
            </li>
            <li>
              <code>Smith, Robert</code>
            </li>
          </ul>
        </li>
        <li>
          Note that if there are biographies or criticisms, these will be listed
          first before the works by the author. For example:
          <br />
          <ul>
            <li>
              <code>White, Patrick -- Works about</code>
            </li>
            <li>
              <code>White, Patrick -- Works by</code>
            </li>
          </ul>
        </li>
        <li>
          For books with 3 authors or less you will be able to find an entry
          under each author. If there are more than 3 authors of a book, you
          will only be able to find the first author listed.
        </li>
      </ul>
      <h2>Corporate author</h2>
      <ul>
        <li>
          Government bodies are considered to be the authors of official
          publications.
        </li>
        <li>
          If you are looking for a government organisation, type in the name of
          the country or state, followed by a full stop, then a space, followed
          by the name of the department. For example:
          <ul>
            <li>
              <code>Australia. Department of Commerce</code>
            </li>
            <li>
              <code>New South Wales. Parliament. Legislative Assembly</code>
            </li>
          </ul>
        </li>
        <li>
          Note that works about a country or state are followed by works by that
          country or state.
        </li>
        <li>
          Companies and societies can also be corporate authors. For example:
          <ul>
            <li>
              <code>Broken Hill Proprietary Co. Ltd.</code>
            </li>
            <li>
              <code>Institution of Civil Engineers</code>
            </li>
          </ul>
        </li>
      </ul>
      <h2>Title</h2>
      <ul>
        <li>Only some titles are listed.</li>
        <li>
          Titles are listed when the author is anonymous or if the book was
          published in a series.
        </li>
      </ul>
      <h2>Subject</h2>
      <ul>
        <li>
          The headings for subjects can be very specific and may differ from
          other library catalogues.
        </li>
        <li>
          If you are looking for a subject, type it in and then scroll down.
        </li>
        <li>
          There are many <em>SEE</em> and <em>SEE ALSO</em> references which
          will direct you to other headings to try.
        </li>
        <li>
          Subjects for the arts are listed by nationality and broad subject
          areas. For example:
          <ul>
            <li>
              <code>French Poetry</code>
            </li>
            <li>
              <code>Australian Drama</code>
            </li>
            <li>
              <code>Vietnamese Language</code>
            </li>
          </ul>
        </li>
      </ul>
      <h2>Subject subheadings</h2>
      <ul>
        <li>
          When searching for a subject with a subheading, for example, Italy --
          History, always use a space before and after the two dashes.
        </li>
        <li>
          It is best to start a search with the name of the country or the
          general subject so that you can see the list of possible subheadings.
        </li>
        <li>
          Many topics as well as countries and states are broken into specific
          subject subheadings. For example:
          <ul>
            <li>
              <code>Artists -- Dictionaries and Encyclopaedias</code>
            </li>
            <li>
              <code>Great Britain -- History</code>
            </li>
            <li>
              <code>New South Wales -- Commerce</code>
            </li>
            <li>
              <code>New South Wales -- Commissions</code> (used for Royal
              Commissions)
            </li>
          </ul>
        </li>
        <li>
          In the same way, subject areas can be divided by geographic areas. For
          example:
          <ul>
            <li>
              <code>Law Reports -- Australia</code>
            </li>
            <li>
              <code>Costume -- Burma</code>
            </li>
          </ul>
        </li>
      </ul>
      <hr />
      <p>Please ask for further assistance at the Information Desk.</p>
    </Layout>
  )
}
